import { useRef, useEffect } from 'react';

// Custom hook to manage the state and behavior of a dropdown component.

const useDropdown = () => {
  const rangeRef = useRef<HTMLDetailsElement | null>(null);
  const modeRef = useRef<HTMLDetailsElement | null>(null);

  // Toggles the open state of the <details> element.

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    if (rangeRef.current) {
      const detailsElement = rangeRef.current as HTMLDetailsElement;
      if (detailsElement.hasAttribute('open')) {
        detailsElement.removeAttribute('open');
      } else {
        detailsElement.setAttribute('open', '');
      }
    }
  };

  const handleClickMode = (event: React.MouseEvent) => {
    event.preventDefault(); // Prevent default behavior of <summary> click
    if (modeRef.current) {
      const detailsElement = modeRef.current as HTMLDetailsElement;
      if (detailsElement.hasAttribute('open')) {
        detailsElement.removeAttribute('open');
      } else {
        detailsElement.setAttribute('open', '');
      }
    }
  };

  // Closes the <details> element if a click is detected outside of it.

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (modeRef.current && !modeRef.current.contains(e.target as Node)) {
        modeRef.current.removeAttribute('open');
      }
      if (rangeRef.current && !rangeRef.current.contains(e.target as Node)) {
        rangeRef.current.removeAttribute('open');
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return {
    rangeRef,
    modeRef,
    handleClickMode,
    handleClick,
  };
};

export default useDropdown;
