export default function queryRange(start: moment.Moment | undefined, end: moment.Moment | undefined): string {
  let qs = [];
  if (start !== undefined) {
    qs.push(`start=${start.format('YYYY-MM-DD')}`);
  }
  if (end !== undefined) {
    qs.push(`end=${end.format('YYYY-MM-DD')}`);
  }
  return qs.length > 0 ? `?${qs.join('&')}` : '';
}
