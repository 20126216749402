import React from 'react';
import * as routes from '../../constants/routes';
import I18n from '../../i18n';
import { Link } from 'react-router-dom';

export default function AccessDenied() {
  return (
    <>
      <div className="modal background" />
      <div className="modal dialog centered networking error">
        <h2>{I18n.t(`solar.errors.access_denied.title`)}</h2>
        <p>{I18n.t(`solar.errors.access_denied.text`)}</p>
        <Link to={routes.HOME}>
          <button>Home</button>
        </Link>
      </div>
    </>
  );
}
