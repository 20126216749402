import { ROLE } from '../constants/roles';

export default function allRolesMatch(requiredRoles: ROLE[], allRoles: ROLE[]): boolean {
  let countMatches = 0;
  let countMissMatches = 0;
  requiredRoles.forEach((r) => {
    if (allRoles.includes(r)) {
      countMatches++;
    } else {
      countMissMatches++;
    }
  });

  return (
    requiredRoles.length > 0 &&
    countMatches + countMissMatches === requiredRoles.length &&
    countMatches === requiredRoles.length
  );
}
