import { useState, useEffect, useCallback } from 'react';
import { useFleets } from '../context/fleetContext';
import { useVehicleStatus } from '../fetchers/vehicleStatus';
import { useEnergyEarnings } from '../fetchers/energy';
import getDefaultTimeRange from '../utils/getDefaultTimeRange';
import { UiVehicleOperatingMode } from '../fetchers/vehicleOperatingModes';

const useVehicleData = (vehicleId: number | undefined, vehicleOperatingModes: UiVehicleOperatingMode[] | undefined) => {
  const { currentFleet } = useFleets();
  const vehicleStatus = useVehicleStatus();
  const energyEarnings = useEnergyEarnings();

  // set default time mode to month so that only data for the current month is loaded
  const defaultRange = getDefaultTimeRange('month');

  const [range, setRange] = useState(defaultRange);
  const [timeMode, setTimeMode] = useState('month');

  // Memoize the query functions
  const memoizedEnergyQuery = useCallback(
    async (fleet, vehicleId, start, end, mode) => {
      try {
        const result = energyEarnings.query(fleet, vehicleId, start, end, mode);

        return result;
      } catch (error) {
        return null;
      }
    },
    [energyEarnings],
  );

  const memoizedVehicleStatusQuery = useCallback(
    async (fleet, start, end) => {
      try {
        const result = await vehicleStatus.query(fleet, start, end);

        return result;
      } catch (error) {
        return null;
      }
    },
    [vehicleStatus],
  );

  useEffect(() => {
    const fetchData = async () => {
      if (currentFleet) {
        if (timeMode === 'month') {
          await memoizedEnergyQuery(currentFleet, vehicleId, range[0], range[1], 'day');
        } else {
          await memoizedEnergyQuery(currentFleet, vehicleId, range[0], range[1], 'month');
        }
      }
    };

    fetchData();
  }, [currentFleet, range, vehicleId, vehicleOperatingModes]);

  useEffect(() => {
    const fetchData = async () => {
      if (currentFleet) {
        await memoizedVehicleStatusQuery(currentFleet, range[0], range[1]);
      }
    };

    fetchData();
  }, []);

  // Use useCallback for setTimeMode
  const handleTimeModeChange = useCallback((mode) => {
    setTimeMode(mode);
  }, []);

  return {
    range,
    setRange,
    timeMode,
    handleTimeModeChange,
    energyData: energyEarnings.queryResult,
    vehicleStatusData: vehicleStatus.queryResult,
  };
};

export default useVehicleData;
