import React from 'react';

export default function Spinner() {
  return (
    <div className="spinner">
      <div className="spinnerContainer">
        <div className="spinnerEl" />
      </div>
    </div>
  );
}
