import React from 'react';
import { useFleets } from '../../context/fleetContext';
import Footer from './footer';
import FallbackCard from './fallbackCard';
import Menu from '../menu/menu';
import { useOpenMenu } from '../../context/openMenuContext';
import useWindowDimensions from '../../hooks/useWindow';

export default function Common({ children }: React.PropsWithChildren<{}>) {
  const { fleets } = useFleets();
  const { isOpen } = useOpenMenu();
  const { width } = useWindowDimensions();

  return (
    <main>
      <Menu />
      <section className={isOpen && width <= 1024 ? 'blur' : ''}>
        {fleets && fleets.length > 0 ? children : <FallbackCard />}
        <Footer />
      </section>
    </main>
  );
}
