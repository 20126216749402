import { Range } from './formatting';
import moment from 'moment';

/**
 * Determines the default time range for the given timeMode. This function is used to transform string time range names
 * into proper Ranges using Moment.
 * @param timeMode can be 'all-time', 'year' or 'month' (default)
 * @returns A {@link,Range} containing start and end as a {@link,Moment}
 */

export default function getDefaultTimeRange(timeMode: string): Range {
  switch (timeMode) {
    case 'all-time':
      return [undefined, undefined];
    case 'year':
      return [moment().startOf('year'), moment().endOf('year')];
    default:
      let defaultMonth: moment.Moment;
      // we show the previous month on the first day of every new month.
      // customers won't see an empty graph.
      if (moment().date() === 1) defaultMonth = moment().subtract({ months: 1 });
      else defaultMonth = moment();

      return [defaultMonth.startOf('month'), defaultMonth.clone().endOf('month')];
  }
}
