import I18n from '../i18n';

interface Tokens {
  access_token: string;
  access_token_lifespan: number;
  refresh_token: string;
}

export async function postLogin(email: string, code: string): Promise<Tokens | undefined> {
  const res = await fetch('/api/auth', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      grant_type: 'password',
      username: email,
      password: code,
      client_id: 'solar',
    }),
  });
  if (!res.ok) {
    if (res.status === 401) {
      return undefined;
    }
    throw await res.text();
  }
  return await res.json();
}

export async function postRefresh(refreshToken: string): Promise<Tokens | undefined> {
  const res = await fetch('/api/auth', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      client_id: 'solar',
    }),
  });
  if (!res.ok) {
    if (res.status === 401) {
      return undefined;
    }
    throw await res.text();
  }
  return await res.json();
}

export async function postRequestLoginToken(email: string): Promise<void> {
  const res = await fetch('/api/auth/login_token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': I18n.currentLocale(),
    },
    body: JSON.stringify({
      connection: 'email',
      email,
      client_id: 'solar',
    }),
  });
  if (!res.ok) {
    throw new Error(await res.text());
  }
}

export async function postRevokeRefreshToken(accessToken: string, refreshToken: string): Promise<void> {
  const res = await fetch('/api/auth/revoke', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': I18n.currentLocale(),
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      refresh_token: refreshToken,
    }),
  });
  if (!res.ok) {
    throw await res.text();
  }
}
