export enum ROLE {
  SUPER_ADMIN = 'superadmin',
  GLOBAL_ADMIN = 'globaladmin',
  FLEET_ADMIN = 'admin',
  VIEWER = 'viewer',
  GUEST = 'guest',

  CAN_VIEW_USERS = 'CAN_VIEW_USERS',
  CAN_ADD_USERS = 'CAN_ADD_USERS',
  CAN_EDIT_USERS = 'CAN_EDIT_USERS',
  CAN_DELETE_USERS = 'CAN_DELET_USERS',

  CAN_VIEW_VEHICLES = 'CAN_VIEW_VEHICLES',
  CAN_ADD_VEHICLES = 'CAN_ADD_VEHICLES',
  CAN_EDIT_VEHICLES = 'CAN_EDIT_VEHICLES',
  CAN_EDIT_ALL_VEHICLE_ATTRIBUTES = 'CAN_EDIT_ALL_VEHICLE_ATTRIBUTES',
  CAN_EDIT_SPECIFIC_ATTRIBUTES_VEHICLES = 'CAN_EDIT_SPECIFIC_ATTRIBUTES_VEHICLES',
  CAN_SET_OPERATION_MODE_VEHICLES = 'CAN_SET_OPERATION_MODE_VEHICLES',
  CAN_DELETE_VEHICLES = 'CAN_DELETE_VEHICLES',

  CAN_VIEW_VEHICLE_DETAILS = 'CAN_VIEW_VEHICLE_DETAILS',

  CAN_VIEW_OPERATION_MODES = 'CAN_VIEW_OPERATION_MODES',
  CAN_ADD_OPERATION_MODES = 'CAN_ADD_OPERATION_MODES',
  CAN_EDIT_OPERATION_MODES = 'CAN_EDIT_OPERATION_MODES',
  CAN_DELETE_OPERATION_MODES = 'CAN_DELET_OPERATION_MODES',

  CAN_VIEW_FLEETS = 'CAN_VIEW_FLEETS',
  CAN_ADD_FLEETS = 'CAN_ADD_FLEETS',
  CAN_EDIT_FLEETS = 'CAN_EDIT_FLEETS',
  CAN_DELETE_FLEETS = 'CAN_DELETE_FLEETS',

  CAN_VIEW_FAQS = 'CAN_VIEW_FAQS',
  CAN_ADD_FAQ_GROUP = 'CAN_ADD_FAQS',
  CAN_ADD_FAQ_QUESTIONS = 'CAN_ADD_FAQ_QUESTIONS',
  CAN_EDIT_FAQ_GROUPS = 'CAN_EDIT_FAQS',
  CAN_EDIT_FAQ_QUESTIONS = 'CAN_ADD_FAQ_QUESTIONS',
  CAN_DELETE_FAQS = 'CAN_DELETE_FAQS',

  CAN_VIEW_DASHBOARD = 'CAN_VIEW_DASHBOARD',
  CAN_VIEW_MY_FLEET = 'CAN_VIEW_MY_FLEET',
  CAN_LOGIN = 'CAN_LOGIN',
}

export const hirachie = {
  [ROLE.FLEET_ADMIN]: [
    ROLE.CAN_VIEW_USERS,
    ROLE.CAN_ADD_USERS,
    ROLE.CAN_EDIT_USERS,
    ROLE.CAN_DELETE_USERS,
    ROLE.CAN_VIEW_VEHICLES,
    ROLE.CAN_EDIT_VEHICLES,
    ROLE.CAN_EDIT_SPECIFIC_ATTRIBUTES_VEHICLES,
    ROLE.CAN_DELETE_VEHICLES,
    ROLE.CAN_VIEW_OPERATION_MODES,
    ROLE.CAN_ADD_OPERATION_MODES,
    ROLE.CAN_EDIT_OPERATION_MODES,
    ROLE.CAN_DELETE_OPERATION_MODES,
    ROLE.CAN_VIEW_FAQS,
    ROLE.CAN_VIEW_DASHBOARD,
    ROLE.CAN_VIEW_MY_FLEET,
    ROLE.CAN_VIEW_VEHICLE_DETAILS,
  ],
  [ROLE.VIEWER]: [ROLE.CAN_VIEW_DASHBOARD, ROLE.CAN_VIEW_FAQS, ROLE.CAN_VIEW_VEHICLE_DETAILS, ROLE.CAN_VIEW_MY_FLEET],
  [ROLE.SUPER_ADMIN]: [
    ROLE.CAN_VIEW_USERS,
    ROLE.CAN_ADD_USERS,
    ROLE.CAN_EDIT_USERS,
    ROLE.CAN_DELETE_USERS,
    ROLE.CAN_VIEW_VEHICLES,
    ROLE.CAN_ADD_VEHICLES,
    ROLE.CAN_EDIT_VEHICLES,
    ROLE.CAN_EDIT_ALL_VEHICLE_ATTRIBUTES,
    ROLE.CAN_EDIT_SPECIFIC_ATTRIBUTES_VEHICLES,
    ROLE.CAN_DELETE_VEHICLES,
    ROLE.CAN_VIEW_OPERATION_MODES,
    ROLE.CAN_ADD_OPERATION_MODES,
    ROLE.CAN_EDIT_OPERATION_MODES,
    ROLE.CAN_DELETE_OPERATION_MODES,
    ROLE.CAN_SET_OPERATION_MODE_VEHICLES,
    ROLE.CAN_VIEW_FLEETS,
    ROLE.CAN_ADD_FLEETS,
    ROLE.CAN_EDIT_FLEETS,
    ROLE.CAN_DELETE_FLEETS,
    ROLE.CAN_VIEW_FAQS,
    ROLE.CAN_ADD_FAQ_GROUP,
    ROLE.CAN_ADD_FAQ_QUESTIONS,
    ROLE.CAN_EDIT_FAQ_GROUPS,
    ROLE.CAN_EDIT_FAQ_QUESTIONS,
    ROLE.CAN_DELETE_FAQS,

    ROLE.CAN_VIEW_DASHBOARD,
    ROLE.CAN_VIEW_MY_FLEET,
    ROLE.CAN_VIEW_VEHICLE_DETAILS,
  ],
  [ROLE.GLOBAL_ADMIN]: [
    ROLE.CAN_VIEW_USERS,
    ROLE.CAN_ADD_USERS,
    ROLE.CAN_EDIT_USERS,
    ROLE.CAN_DELETE_USERS,
    ROLE.CAN_VIEW_VEHICLES,
    ROLE.CAN_ADD_VEHICLES,
    ROLE.CAN_EDIT_VEHICLES,
    ROLE.CAN_EDIT_ALL_VEHICLE_ATTRIBUTES,
    ROLE.CAN_EDIT_SPECIFIC_ATTRIBUTES_VEHICLES,
    ROLE.CAN_DELETE_VEHICLES,
    ROLE.CAN_VIEW_OPERATION_MODES,
    ROLE.CAN_ADD_OPERATION_MODES,
    ROLE.CAN_EDIT_OPERATION_MODES,
    ROLE.CAN_DELETE_OPERATION_MODES,
    ROLE.CAN_SET_OPERATION_MODE_VEHICLES,
    ROLE.CAN_VIEW_FAQS,
    ROLE.CAN_ADD_FAQ_GROUP,
    ROLE.CAN_ADD_FAQ_QUESTIONS,
    ROLE.CAN_EDIT_FAQ_GROUPS,
    ROLE.CAN_EDIT_FAQ_QUESTIONS,
    ROLE.CAN_DELETE_FAQS,

    ROLE.CAN_VIEW_DASHBOARD,
    ROLE.CAN_VIEW_MY_FLEET,
    ROLE.CAN_VIEW_VEHICLE_DETAILS,
  ],
  [ROLE.GUEST]: [ROLE.CAN_LOGIN],
};
