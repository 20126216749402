import React from 'react';

type MenuLinkProps = React.PropsWithChildren<{ href: string; match?: string; disabled?: boolean; onClick: () => void }>;

export default function MenuLink({ href, match, children, disabled, onClick }: MenuLinkProps) {
  const isActive = match ? window.location.href.match(match) : window.location.href.endsWith(href);

  return (
    <li className={`${isActive ? 'active' : ''} ${disabled ? 'disabled' : ''}`}>
      <a href={disabled ? '#' : href} onClick={() => onClick()}>
        {children}
      </a>
    </li>
  );
}
