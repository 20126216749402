import React, { useState, useCallback, lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom';
import I18n from './i18n';
import { ModalProvider } from './context/modalContext';
import { AuthProvider } from './context/authContext';
import { OpenMenuProvider } from './context/openMenuContext';
import { ROLE } from './constants/roles';
import './index.scss';
import { PrivateRoute } from './privateRoute';
import { VehicleOperatingModesProvider } from './context/vehicleOperatingModesContext';

const Login = lazy(() => import('./pages/login'));
const VehicleDetails = lazy(() => import('./pages/vehicleDetails'));
const Documents = lazy(() => import('./pages/documents'));
const Admin = lazy(() => import('./pages/admin'));
const Fleets = lazy(() => import('./components/fleets'));
const Dashboard = lazy(() => import('./pages/dashboard'));
const MyFleetPage = lazy(() => import('./pages/myFleetPage'));
const Settings = lazy(() => import('./pages/settings'));
const Faqs = lazy(() => import('./pages/faqs'));
const LocaleSwitcher = lazy(() => import('./components/common/localeSwitcher'));

function Index() {
  const [locale, _setLocale] = useState(I18n.currentLocale());

  const setLocale = useCallback((l: string) => {
    I18n.setLocale(l || I18n.currentLocale());
    _setLocale(l || I18n.currentLocale());
  }, []);

  return (
    <>
      <HashRouter>
        <ModalProvider>
          <AuthProvider>
            <OpenMenuProvider>
              <LocaleSwitcher value={locale} onLocaleSelected={setLocale} />
              <Routes>
                <Route path="login" element={<Login />} />
                <Route
                  path=":fleet/fleets"
                  element={
                    <PrivateRoute roles={[ROLE.CAN_VIEW_FLEETS]}>
                      <Fleets />
                    </PrivateRoute>
                  }
                />
                <Route
                  path=":fleet"
                  element={
                    <PrivateRoute roles={[ROLE.CAN_VIEW_DASHBOARD]}>
                      <VehicleOperatingModesProvider>
                        <Dashboard />
                      </VehicleOperatingModesProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path=":fleet/fleet"
                  element={
                    <PrivateRoute roles={[ROLE.CAN_VIEW_MY_FLEET]}>
                      <MyFleetPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path=":fleet/details/:vehicleId"
                  element={
                    <PrivateRoute roles={[ROLE.CAN_VIEW_VEHICLE_DETAILS]}>
                      <VehicleOperatingModesProvider>
                        <VehicleDetails />
                      </VehicleOperatingModesProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path=":fleet/settings"
                  element={
                    <PrivateRoute roles={[ROLE.CAN_VIEW_VEHICLES, ROLE.CAN_VIEW_USERS]}>
                      <Settings />
                    </PrivateRoute>
                  }
                />
                <Route
                  path=":fleet/admin"
                  element={
                    <PrivateRoute roles={[ROLE.CAN_VIEW_FLEETS, ROLE.CAN_VIEW_USERS]}>
                      <Admin />
                    </PrivateRoute>
                  }
                />
                <Route
                  path=":fleet/reports"
                  element={
                    <PrivateRoute roles={[ROLE.CAN_VIEW_DASHBOARD]}>
                      <Documents documentType="reports" />
                    </PrivateRoute>
                  }
                />
                <Route
                  path=":fleet/docs"
                  element={
                    <PrivateRoute roles={[ROLE.CAN_VIEW_DASHBOARD]}>
                      <Documents documentType="docs" />
                    </PrivateRoute>
                  }
                />
                <Route
                  path=":fleet/faqs"
                  element={
                    <PrivateRoute roles={[ROLE.CAN_VIEW_FAQS]}>
                      <Faqs />
                    </PrivateRoute>
                  }
                />
                <Route path="/" element={<Navigate replace to={'/*'} />} />
              </Routes>
            </OpenMenuProvider>
          </AuthProvider>
        </ModalProvider>
      </HashRouter>
    </>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div></div>}>
      <Index />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);
