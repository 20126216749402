import React, { useEffect } from 'react';
import { useFleets, useVehicles } from '../../context/fleetContext';
import I18n from 'i18n-js';
import { useAuth } from '../../context/authContext';
import MenuLink from './menuLink';
import './menu.scss';
import { useOpenMenu } from '../../context/openMenuContext';
import useDropdown from '../../hooks/useDropdown';
import { useNavigate } from 'react-router-dom';

export default function Menu() {
  const { fleets, currentFleet } = useFleets();
  const { vehicles } = useVehicles();
  const { currentUserName } = useAuth();
  const role = fleets.find((f) => f.slug === currentFleet)?.role;
  const isGlobalAdmin = fleets.find((f) => f.slug === 'admin' && f.role === 'admin');
  const isAdminFleetSelected = currentFleet === 'admin';
  const { isOpen, toggle } = useOpenMenu();
  const { rangeRef, handleClick } = useDropdown();
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    document.body.className = isOpen ? 'menuOpen' : 'menuClosed';
  }, [isOpen]);

  const handleLogout = () => {
    logout();
    navigate('/login', { replace: true });
  };

  const items = [
    {
      title: I18n.t('solar.dashboard.title'),
      href: `#/${currentFleet}`,
    },
    {
      title: I18n.t('solar.myfleet.title'),
      href: `#/${currentFleet}/fleet`,
      disabled: isAdminFleetSelected,
    },
    {
      title: I18n.t('solar.vehicleDetails.title'),
      href: `#/${currentFleet}/details/${vehicles[0]?.id}`,
      disabled: isAdminFleetSelected,
      match: `#/${currentFleet}/details/.*$`,
    },
    {
      title: I18n.t('solar.documents.reports.menuTitle'),
      href: `#/${currentFleet}/reports`,
      disabled: isAdminFleetSelected,
    },
    {
      title: I18n.t('solar.documents.docs.title'),
      href: `#/${currentFleet}/docs`,
    },
    {
      title: I18n.t('solar.faqs.title'),
      href: `#/${currentFleet}/faqs`,
    },
    {
      title: I18n.t('solar.settings.title'),
      href: `#/${currentFleet}/settings`,
      disabled: isAdminFleetSelected,
      hidden: role !== 'admin',
    },
    {
      title: I18n.t('solar.admin.title'),
      href: `#/admin/admin`,
      hidden: !isGlobalAdmin,
    },
  ];

  return (
    <>
      <div className={`menuBar ${isOpen ? 'open' : 'closed'}`}>
        <button onClick={() => toggle(!isOpen)}>
          <div className="hamburger">
            <span className="hamburgerLine"></span>
            <span className="hamburgerLine"></span>
            <span className="hamburgerLine"></span>
          </div>
          <p className={`current ${isOpen && 'open'}`}>
            {isOpen
              ? 'Close'
              : items.find((item) =>
                  item.match ? window.location.href.match(item.match) : window.location.href.endsWith(item.href),
                )?.title ?? I18n.t('solar.dashboard.title')}
          </p>
        </button>
        <img src="sono_small.png" alt="" />
      </div>
      <div className={`menu ${isOpen ? 'open' : 'closed'}`}>
        <img src="sono.png" alt="Sono Motors" />
        <ul>
          {items
            .filter((item) => !item.hidden)
            .map((item, idx) => (
              <MenuLink key={idx} {...item} onClick={() => toggle(!isOpen)}>
                {item.title}
              </MenuLink>
            ))}
        </ul>
        {isGlobalAdmin && (
          <div className="client">
            <label htmlFor="client">{I18n.t('solar.menu.client')}</label>
            <details className="custom-select" ref={rangeRef}>
              <summary className="radios" onClick={handleClick}>
                {fleets.map(({ slug, name }) => {
                  return (
                    <input
                      type="radio"
                      key={slug}
                      name="fleet"
                      id={slug}
                      title={name}
                      value={slug}
                      checked={slug === currentFleet}
                      onChange={(e) => (window.location.href = `#/${e.target.value}`)}
                    />
                  );
                })}
              </summary>
              <ul className="list fleetList">
                {fleets.map(({ slug, name }) => (
                  <li key={slug}>
                    <label htmlFor={slug}>{name}</label>
                  </li>
                ))}
              </ul>
            </details>
          </div>
        )}
        <div className="logout">
          {!isGlobalAdmin && <p className="companyName">{fleets.find((f) => f.slug === currentFleet)?.name}</p>}
          {currentUserName && <p className="userName">{currentUserName}</p>}
          <a href="#/logout" onClick={handleLogout}>
            {I18n.t('solar.menu.logout')}
          </a>
        </div>
      </div>
    </>
  );
}
