import React from 'react';
import { useParams, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './context/authContext';
import isGranted from './utils/isGranted';
import { FleetsProvider, useFleets } from './context/fleetContext';
import { ROLE } from './constants/roles';
import AccessDenied from './components/common/accessDenied';
import Common from './components/common/common';

export function PrivateRoute({ children, roles }: { children: JSX.Element; roles: ROLE[] }) {
  const { fleet } = useParams();
  let location = useLocation();
  const { isLoggedIn, isLoading } = useAuth();
  if (isLoading) {
    return <p className="container">Checking auth..</p>;
  }
  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return (
    <FleetsProvider currentFleet={fleet || '*'}>
      <IsGranted roles={roles} onDecline={<AccessDenied />}>
        <Common key={fleet}>{children}</Common>
      </IsGranted>
    </FleetsProvider>
  );
}

type isGrantedProps = {
  children: JSX.Element;
  roles: ROLE[];
  onDecline?: JSX.Element;
};

export function IsGranted({ children, roles, onDecline }: isGrantedProps): JSX.Element {
  const { isLoggedIn, isLoading } = useAuth();
  const fleets = useFleets()?.fleets,
    currentFleet = useFleets()?.currentFleet;
  if (!fleets || !currentFleet)
    if (!isLoggedIn || isLoading || fleets === undefined || !currentFleet === undefined) {
      return <></>;
    }

  return isGranted(roles, fleets, currentFleet) ? children : onDecline ? onDecline : <></>;
}
