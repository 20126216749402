import React, { createContext, useContext, useState, useCallback } from 'react';
import Modal from '../components/common/modal';

type ModalFn = <T>(content: (close: (result: T) => void) => React.ReactNode) => Promise<T>;

const ModalContext = createContext((async () => {}) as ModalFn);

export function ModalProvider({ children }: React.PropsWithChildren<{}>) {
  const [dialog, setDialog] = useState(null as React.ReactNode | null);

  const modal = useCallback(
    <T extends unknown>(content: (close: (result: T) => void) => React.ReactNode) =>
      new Promise<T>((resolve) =>
        setDialog(
          content((result) => {
            resolve(result);
            setDialog(null);
          }),
        ),
      ),
    [],
  );

  return (
    <ModalContext.Provider value={modal}>
      {dialog && <Modal>{dialog}</Modal>}
      {children}
    </ModalContext.Provider>
  );
}

export const useModal = () => useContext(ModalContext);
